.cancellation-refund .sub-heading{
    @apply text-[20px] font-bold
    }
    .cancellation-refund .point{
        @apply flex items-start gap-[5px]
    }
    .cancellation-refund .point span{
        @apply mt-1
    }
    .cancellation-refund p{
        @apply text-[18px]
    }
    .cancellation-refund .contents{
        @apply flex flex-col gap-[10px]
        
    }