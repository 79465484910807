.PrivacyPolicy .sub-heading{
@apply text-[20px] font-bold
}
.PrivacyPolicy .point{
    @apply flex items-start gap-[5px]
}
.PrivacyPolicy .point span{
    @apply mt-1
}
.PrivacyPolicy p{
    @apply text-[18px]
}
.PrivacyPolicy .contents{
    @apply flex flex-col gap-[10px]
    
}