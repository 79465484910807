.footer-container {
  padding: 80px 100px 20px 100px;
  background-color: #333132;
  height: auto;
  width: 100%;
}
.footer-main-content {
  display: flex;
  width: 100%;
}
.footer-content-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}
/* .footer-content-1 img {
  height: 100px;
  width: 100px;
} */
.footer-content-1 p {
  font-size: 18px;
  max-width: 80%;
  color: #c2c2c2;
}
.footer-content-2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  padding-left: 60px;
}
.input-footer {
  display: flex;
  gap: 20px;
  align-items: center;
}
.footer-content-2 h1 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}
.footer-content-2 p {
  font-size: 18px;
  font-weight: 400;
  color: white;
}
.email-box {
  border: none;
  padding: 1.5rem;
  width: 60%;
  border-radius: 10px;
  background-color: #1d1d21;
  font-size: 16px;
  color: #606060;
}
.input-footer button {
  padding: 1.5rem 3rem;
  background-color: #1c75bc;
  font-size: 16px;
  font-weight: 500;
  color: white;
  border-radius: 10px;
}
.footer-line {
  height: 1px;
  width: 100%;
  background-color: #606060;
  margin-top: 50px;
}
.footer-content-2 h2 {
  font-size: 18px;
  font-weight: 400;
  color: #c2c2c2;
}
@media screen and (max-width: 1024px) {
  .footer-container {
    padding: 30px 30px 20px 30px;
  }


}

@media screen and (max-width: 640px) {
  .footer-container {
    padding: 30px 15px 30px 15px;
  }
  .footer-main-content {
    display: flex;

    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .footer-content-1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .footer-content-1 p {
    font-size: 18px;
    max-width: 100%;
    color: #c2c2c2;
  }
  .footer-content-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-left: 1px;
  }
}