.quizsection-main{
    padding: 15px 80px 0px 80px;
    background-color: #f5f5f5;
    height: auto;
 
    /* width: 100%; */
}
.quiz-section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.header-buttons{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    /* margin-top: -30px; */

}
.close-btns{
    display: flex;
    gap: 0px;
    align-items: center;
    justify-content: center;
    font-size: 16px;

}
.profile-buttons button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 2px solid #1C75BC;
    border-radius: 50px;
    padding: 5px 5px 5px 20px;
    font-weight: 500;
    font-size: 16px;
    gap: 20px;
    background-color: transparent;

}
.quiz-headerprofile-buttons button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 2px solid #1C75BC;
    border-radius: 50px;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 16px;
    gap: 20px;
    background-color: transparent;

}
.quiz-section-logo img{
    /* width: 8.75rem; */
    /* height: 3.75rem; */
    height: 5rem
}

@media screen and (max-width: 640px) {
    .quiz-headerprofile-buttons button{
        display: none;
    }
   
    .header-buttons {
        gap:0px;

    }
    .profile-buttons button{
        padding: 0.5rem 1rem;
        font-size: 14px;
        display: none;
    }
    .profile-buttons{
        display: none;
    }
    
}
