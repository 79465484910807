.energy-main {
  padding: 35px 80px 0px 80px;
}
.energy-main-box {
  display: flex;
  border-radius: 20px;
  /* height: 50%; */
  background-color: white;
}
.energy-image {
  height: 60%;
  width: 40%;
}
.energy-content {
  border-radius: 0px 20px 20px 0px;
  width: 60%;
}
.energy-image img {
  height: 100%;
  width: 90%;
  object-fit: cover;
  border-radius: 20px 0px 0px 20px;
}
.energy-content {
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 1rem; */
  text-align: center;
  height: auto;
}
.above-section {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.percentege-box {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  display: flex;
}
.percentage-boxenergy-1 {
  background-color: #d9d9d9;
  border-radius: 50px 0px 0px 50px;
}
.percentage-boxenergy-2 {
  background-color:#3976f9;
  border-radius: 0px 50px 50px 0px;
}
.belowsection {
  padding: 0px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.percentage-numaric {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.below-back-section {
  display: flex;
  justify-content: space-between;
}
.above-section h1 {
  font-size: 48px;
  font-weight: 600;
  color: #1c75bc;
}
.above-section h2 {
  font-size: 24px;
  font-weight: 600;
}
.numaric-first {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.numaric-first p {
  font-size: 16px;
  font-weight: 400;
}
.numaric-second {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.numaric-second p {
  font-size: 16px;
  font-weight: 400;
}
.above-section p {
  font-weight: 400;
  padding-top: 0rem;
}
.below-back-section {
  margin-bottom: 10px;
}
.first-back {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  color: #333132;
}
.first-next {
  padding: 15px 60px;
  border-radius: 5px;
  font-size: 19px;
  background-color: #1c75bc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}
.celibraity-name {
  margin-top: 50px !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}
.above-section h3 {
  font-size: 24px;
  font-weight: 400;
}
.third-heading {
  font-size: 24px;
  font-weight: 400;
}
.centered-button {
  margin: 0 auto;
  display: block;
}
.second-heading {
  font-size: 24px;
  font-weight: 400;
}
.above-section h4 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 6rem;
  color: #1c75bc;
}

.above-section-child {
  display: "flex";
  padding: "0 80px";
  flex-direction: "column";
  gap: "10px";
}
@media screen and (max-width: 1024px) {
  .energy-image img {
    height: 500px;
    object-fit: cover;
    width: 100%;
  }
  .energy-image{
    width: 100%;
  }
  .energy-main {
    padding: 100px 30px 100px 30px;
}
  .above-section h3 {
    font-size: 18px;
    font-weight: 400;
  }
  .above-section h4 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 6rem;
    color: #1c75bc;
  }
  .above-section h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .above-section p {
    font-weight: 400;

    font-size: 1rem !important;
  }
  .energy-main-box {
    display: flex;
    border-radius: 20px;
    /* height: 50%; */
    background-color: white;
    
}

.energy-content {
  width: 100%;
}
}
@media screen and (max-width: 768px) {
  .energy-main-box {
    display: flex;
  
    padding: 10px 10px 10px 10px;
  }
  .energy-main {
    padding: 30px 30px 30px 30px;
  }
  .above-section {
    padding: 20px 20px 20px 20px;
  }
  .energy-image {
    width: 100%;
    border-radius: 20px;
    height: 280px;
  }
  .energy-image img {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
  }
  .energy-content {
    width: 100%;
  }
  .above-section h4 {
    line-height: 4rem;
    font-size: 32px;
  }
  .belowsection {
    padding: 10px 10px 10px 10px;
    gap: 1px;
  }
  .above-section h3 {
    font-size: 16px;
  }
  .belowsection p {
    font-size: 16px;
  }
  .above-section h2 {
    font-size: 18px;
  }
  .above-section h1 {
    font-size: 32px;
  }
  .above-section-child {
    padding: 0px 0px;
  }
  .energy-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* gap: 1rem; */
    text-align: center;
    height: auto;
}

}
@media screen and (max-width: 640px) {
  .energy-main-box {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
  }
  .energy-main {
    padding: 20px 20px 20px 20px;
  }
  .above-section {
    padding: 20px 20px 20px 20px;
  }
  .energy-image {
    width: 100%;
    border-radius: 20px;
    height: 280px;
  }
  .energy-image img {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
    height: 280px;
  }
  .energy-content {
    width: 100%;
  }
  .above-section h4 {
    line-height: 4rem;
    font-size: 32px;
  }
  .belowsection {
    padding: 10px 10px 10px 10px;
    gap: 1px;
  }
  .above-section h3 {
    font-size: 16px;
  }
  .belowsection p {
    font-size: 16px;
  }
  .above-section h2 {
    font-size: 18px;
  }
  .above-section h1 {
    font-size: 32px;
  }
  .above-section-child {
    padding: 0px 0px;
  }
}