.animate-customspin {
  animation: spin 1.5s linear infinite;
}

.progressBar {
  appearance: none;
  height: 10px;
  border-radius: 100px;
}

.progressBar::-webkit-progress-bar {
  background: #b6c3ea;
  border-radius: 100px;
}

.progressBar::-webkit-progress-value {
  /* background-color: #c07e1ba7; */
  background-color: #3573F9;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  transition: width 1s;
}

.progressBar::-moz-progress-bar {
  /* background-color: #c07e1ba7; */
  background-color: #3573F9;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg); /* Hold the final position */
  }
}
