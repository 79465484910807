.home-div {
  padding-top: 1rem;

  background-color: #f5f5f5;
}

.home-hero {
  width: 100%;
  height: 100vh;
  background: url("../../Assets/hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: -35px;
  z-index: 20;
}
.home-hero-text {
  width: 100%;
  position: absolute;
  top: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-hero-text h1 {
  font-size: 56px;
  line-height: 78.4px;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(to right, #1c36bc, #1c75bc);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.heading-p-div {
  margin-top: 38px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.home-hero-text p {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
.career-path-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border: 1.5px solid #1c75bc;
  margin: auto;
  width: 28%;
  border-radius: 30px;
  padding: 8px 0;
}
.career-path-div p {
  font-size: 11px !important;
  font-weight: 500;
}
.career-path-imgdiv {
  display: flex;
}
.career-path-imgdiv #person2,
#person3 {
  border-radius: 50%;
}
.hero-text-btn {
  background-color: #1c75bc;
  color: #ffffff;
  padding: 20px 54px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}
.hero-section-buttons {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.down-word-arrow {
  width: 50px;
  height: 50px;
}
.down-arrow-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1300px) {
  body {
    overflow-x: hidden;
  }
  .home-div {
    padding-top: 1rem;
  
    background-color: #f5f5f5;
  }
  .home-hero-text h1 {
    font-size: 65px;
    font-weight: 600;
    line-height: 60px;
  }
  .home-hero-text p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}
.home-hero {
  background-position: center;
  height: 60vw;
  background-position: center;
  background-size: cover;
 
}
.home-hero-text {
  top: 170px;
}
.hero-text-btn  {
  padding: 15px 30px;
  font-size: 18px;
  margin-top: -10px;
}

.career-path-div {
  width: 35%;
}
  
}
@media screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }
  .home-hero {
    background-position: center;
    height: 80vw;
   
  }
  .career-path-div {
    width: 40%;
  }
  .home-hero-text h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
  }
  .home-div {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .home-hero-text p {
    font-size: 20px;
  }
  .hero-text-btn {
    padding: 15px 30px;
  }
  .heading-p-div {
    padding-left: 50px;
    padding-right: 50px;
  }
  .home-hero-text {
    width: 100%;
    position: absolute;
    top: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 850px) {
  .home-hero {
    background-position: center;
    height: 100vw;
}
.career-path-div {
  width: 50%;
}


}

@media screen and (max-width: 768px) {
  .career-path-div {
    width: 50%;
  }
  .home-hero-text h1 {
    font-size: 40px;
  }
  .home-hero-text p {
    font-size: 16px;
  }
  .home-hero {
    height: 100vw;
  }
}

@media screen and (max-width: 640px) {
  body {
    overflow-x: hidden;
  }
  .home-hero {
    background-position: center;
    height: 100vw;
    margin-top: 70px;
  }
  .career-path-div {
    width: 90%;
  }
  .home-hero-text h1 {
    font-size: 27px;
    font-weight: 600;
    line-height: 42px;
  }
  .home-div {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .home-hero-text p {
    font-size: 16px;
  }
  .career-path-div p {
    font-size: 10px !important;
  }
  .heading-p-div {
    padding-left: 30px;
    padding-right: 30px;
  }
  .home-hero-text {
    top: 60px;
  }
  .hero-text-btn {
    display: none;
  }
  .down-word-arrow {
    display: none;
  }
}