.login-page-main {
  display: flex;
  height: 100vh;
  width: 100%;
}
.login-image-section {
  width: 50%;
  background-image: url("../../Assets/portrait-young-boy-with-book-education-day\ 1\ \(1\).png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-content-section {
  width: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: left;
}
.login-content {
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  gap: 70px;
  float: left;
}
.login-button-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.login-button-section h1 {
  font-size: 24px;
  font-weight: 600;
}
.single-button-google {
  display: flex;
}
.google {
  display: flex;
}
.google button {
  padding: 15px 100px;
  background-color: #346ef1;
  font-weight: 700;
  font-size: 14px;
  color: white;
}
.para-of-login {
  font-size: 20px;
  font-weight: 400;
}
.para-of-login span {
  color: #346ef1;
}
.google img{
  height: 50px;
}
@media screen and (max-width: 1024px) {
  .login-button-section h1 {
    font-size: 20px;
    font-weight: 600;
}
.google button {
  padding: 15px 50px;
  background-color: #346ef1;
  font-weight: 700;
  font-size: 14px;
  color: white;
}
.para-of-login {
  font-size: 18px;
  font-weight: 400;
}
}
@media screen and (max-width: 850px) {
  .login-page-main {
    display: flex;
   
    flex-direction: column;
   
  }
  .login-image-section {
    width: 100%;
    background-image: url("../../Assets/portrait-young-boy-with-book-education-day\ 1\ \(1\).png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;

  }
  .login-content-section {
    height: 60vh;
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
  }
  .google button {
    padding: 5px 50px;
    background-color: #346ef1;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }
  .para-of-login {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}
.login-button-section h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.single-button-google {
  display: flex;
  align-items: center;
  justify-content: center;
}
  


}
@media screen and (max-width: 768px) {
  .login-page-main {
    display: flex;
   
    flex-direction: column;
   
  }
  .login-image-section {
    width: 100%;
    background-image: url("../../Assets/portrait-young-boy-with-book-education-day\ 1\ \(1\).png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;

  }
  .login-content-section {
    height: 60vh;
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
  }
  .google button {
    padding: 5px 50px;
    background-color: #346ef1;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }
  .para-of-login {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}
.login-button-section h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.single-button-google {
  display: flex;
  align-items: center;
  justify-content: center;
}
  
 

}

@media screen and (max-width: 640px) {
  .login-page-main {
    display: flex;
   
    flex-direction: column;
   
  }
  .login-image-section {
    width: 100%;
    background-image: url("../../Assets/portrait-young-boy-with-book-education-day\ 1\ \(1\).png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;

  }
  .login-content-section {
    height: 60vh;
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
  }
  .google button {
    padding: 5px 50px;
    background-color: #346ef1;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }
  .para-of-login {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}
.login-button-section h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.single-button-google {
  display: flex;
  align-items: center;
  justify-content: center;
}
  
 

}