.terms-condition .sub-heading{
    @apply text-[20px] font-bold
    }
    .terms-condition .point{
        @apply flex items-start gap-[5px]
    }
    .terms-condition .point span{
        @apply mt-1
    }
    .terms-condition p{
        @apply text-[18px]
    }
    .terms-condition .contents{
        @apply flex flex-col gap-[10px]
        
    }