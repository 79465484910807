.shipping-delivery .sub-heading{
    @apply text-[20px] font-bold
    }
    .shipping-delivery .point{
        @apply flex items-start gap-[5px]
    }
    .shipping-delivery .point span{
        @apply mt-1
    }
    .shipping-delivery p{
        @apply text-[18px]
    }
    .shipping-delivery .contents{
        @apply flex flex-col gap-[10px]
        
    }